<template>
    <section class="pt-0">
        <div class="container vstack gap-4">
            <!-- Title START -->
            <div class="row">
                <div class="col-12">
                    <h1 class="fs-4 mb-0">Rooms</h1>
                </div>
            </div>
            <!-- Title END -->

            <!-- Listing table START -->
            <div class="row">
                <div class="col-12">
                    <div class="row g-4">
                        <div class="card border">
                            <!-- Card header -->
                            <div class="card-header border-bottom d-flex justify-content-between">
                                <h5 class="card-header-title">Rooms</h5>
                                <button class="btn btn-sm btn-primary" @click="addRoom()">Add Room</button>
                            </div>
    
                            <!-- Card body START -->
                            <div class="card-body vstack gap-3">
                                <!-- Listing item START -->
                                <div class="card border p-2" v-for="room, key in rooms" :key="key">
                                    <div class="row g-4">
                                        <!-- Card img -->
                                        <div class="col-md-3 col-lg-2">
                                            <!-- <img src="assets/images/category/hotel/4by3/10.jpg" class="card-img rounded-2" alt="Card image"> -->
                                            <img :src="room.image" class="card-img rounded-2" alt="Card image">
                                        </div>
    
                                        <!-- Card body -->
                                        <div class="col-md-9 col-lg-10">
                                            <div class="card-body position-relative d-flex flex-column p-0 h-100">
                                                <!-- Title -->
                                                <h5 class="card-title mb-0 me-5"><a href="hotel-detail.html">{{
                                    room.room_name }}</a></h5>
                                                <small>{{ room.description }}</small>
    
                                                <!-- Price and Button -->
                                                <div
                                                    class="d-sm-flex justify-content-sm-between align-items-center mt-3 mt-md-auto">
                                                    <!-- Button -->
                                                    <div class="d-flex align-items-center">
                                                        <h5 class="fw-bold mb-0 me-1">₹{{ room.price }}</h5>
                                                        <span class="mb-0 me-2">/day</span>
                                                    </div>
                                                    <!-- Price -->
                                                    <div class="hstack gap-2 mt-3 mt-sm-0">
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-primary mb-0"
                                                            @click="editRoom(room.room_id)"><i
                                                                class="bi bi-pencil-square fa-fw me-1"></i>Edit</a>
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-danger mb-0"
                                                            @click="deleteRoom(room.room_id)"><i
                                                                class="bi bi-trash3 fa-fw me-1"></i>Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Listing item END -->
                            </div>
                            <!-- Card body END -->
                        </div>
                    </div>

                    
                </div>
            </div>
            <!-- Listing table END -->
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            rooms: []
        }
    },

    mounted() {
        this.getRooms();
    },

    methods: {
        getRooms() {
            let vm = this;
            this.$store.dispatch('post', { uri: 'hotel/getRooms' })
                .then(response => {
                    this.rooms = response.data.data;
                })
        },

        addRoom() {
            this.$router.push('/rooms/create');
        },

        editRoom(room_id) {
            this.$router.push('/rooms/update/' + room_id);
        },

        deleteRoom(room_id) {
            let vm = this;
            this.$store.dispatch('post', { uri: 'hotel/deleteRoom', data: { room_id: room_id } })
                .then(response => {
                    this.$store.dispatch('success', response.data.message);
                    this.getRooms();
                })
                .catch(error => {
                    this.$store.dispatch('error', error.response.data.message);
                })
        }
    }
}
</script>