<template>
    <section class="pt-4">
        <div class="container">
            <div class="card rounded-3 border p-3 pb-2">
                <div class="d-sm-flex align-items-center">
                    <div class="avatar avatar-xl mb-2 mb-sm-0">
                        <img class="avatar-img rounded-circle" src="assets/images/avatar/profile.png" alt="">
                    </div>
                    <h4 class="mb-2 mb-sm-0 ms-sm-3"><span class="fw-light">Hi </span>{{ $store.getters.user.name }}</h4>                    
                </div>
                <button class="btn btn-primary w-100 d-block d-xl-none mt-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#dashboardMenu" aria-controls="dashboardMenu">
                    <i class="bi bi-list"></i> Dashboard Menu
                </button>
                <div class="offcanvas-xl offcanvas-end mt-xl-3" tabindex="-1" id="dashboardMenu">
                    <div class="offcanvas-header border-bottom p-3">
                        <h5 class="offcanvas-title">Menu</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#dashboardMenu" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body p-3 p-xl-0">
                        <div class="navbar navbar-expand-xl">
                            <ul class="navbar-nav navbar-offcanvas-menu">
    
                                <li class="nav-item"><router-link to="/dashboard" :class="{active:active_dashboard}" class="nav-link "><i class="bi bi-house-door fa-fw me-1"></i>Dashboard</router-link></li>

                                <li class="nav-item"> <router-link class="nav-link" to="/hotel-detail" :class="{active:active_hotel}" @click="activeTab('hotel')"><i class="bi bi-grid fa-fw me-1"></i>Hotel</router-link> </li>
    
                                <li class="nav-item"> <router-link class="nav-link" to="/rooms/index" :class="{active:active_room}" @click="activeTab('room')"><i class="bi bi-journals fa-fw me-1"></i>Rooms</router-link> </li>

                                <li class="nav-item"> <router-link class="nav-link" to="/facilities" :class="{active:active_facility}" @click="activeTab('facility')"><i class="bi bi-star fa-fw me-1"></i>Facilities</router-link> </li>
            
                                <li class="nav-item"> <router-link class="nav-link" to="/policies" :class="{active:active_policy}" @click="activeTab('policy')"><i class="bi bi-graph-up-arrow fa-fw me-1"></i>Policies</router-link>	</li>

                                <li class="nav-item"> <router-link class="nav-link" to="/images" :class="{active:active_images}" @click="activeTab('images')"><i class="bi bi-images fa-fw me-1"></i>Images</router-link>	</li>
                                
                                <li> <router-link class="nav-link" to="/profile-settings" :class="{active:active_profile}" @click="activeTab('profile')"><i class="bi bi-gear fa-fw me-1"></i>Profile Settings</router-link></li>

                                <li class="nav-item"> <router-link class="nav-link" to="/bookings" :class="{active:active_booking}" @click="activeTab('booking')"><i class="bi bi-bookmark-heart fa-fw me-1"></i>Bookings</router-link> </li>
    		
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{
    name: "Main",
    data() {
        return {
            user: {
                email: "",
                password: ""
            },
            active_dashboard: true,
            active_hotel:false,
            active_room:false,
            active_facility:false,
            active_policy:false,
            active_profile:false,
            active_booking:false,
            active_images:false,
            errors: [],
        };
    },
    beforeRouteEnter(to, from, next) {
            next((vm) => {
                console.log("to", to)
            });
        },

    mounted() {
    },
    
    methods:{
        activeTab(tab){
            this.active_dashboard = false;
            console.log("tab", tab)
        }
    }
}
</script>