<template>
    <section class="vh-xxl-100">
        <div class="container h-100 d-flex px-0 px-sm-4">
            <div class="row justify-content-center align-items-center m-auto">
                <div class="col-12">
                    <div class="bg-mode shadow rounded-3 overflow-hidden">
                        <div class="row g-0">
                            <div class="col-lg-6 d-md-flex align-items-center order-2 order-lg-1">
                                <div class="p-3 p-lg-5">
                                    <img src="assets/images/element/signin.svg" alt="">
                                </div>
                                <div class="vr opacity-1 d-none d-lg-block"></div>
                            </div>
            
                            <!-- Information -->
                            <div class="col-lg-6 order-1">
                                <div class="p-4 p-sm-6">
                                    <img class="h-120px mb-4" src="assets/images/logo-icon.png" alt="logo">
                                    <h1 class="mb-2 h3">Vendor Registration</h1>
                                    <p class="mb-0">Already a member?<router-link to="/"> Log in</router-link></p>
            
                                    <!-- Form START -->
                                    <form class="row mt-4 text-start" @submit.prevent="registerUser">
                                        <div class="col-sm-12 mb-3">
                                            <label class="form-label">Enter Name <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="Enter your Name" :class="{ 'is-invalid': errors.name }" v-model="user.name" >
                                            <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                        </div>
                                        <div class="col-sm-6 mb-3">
                                            <label class="form-label">Enter email id <span class="text-danger">*</span></label>
                                            <input type="email" class="form-control" placeholder="Enter your Email" :class="{ 'is-invalid': errors.email }" v-model="user.email">
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>

                                        <div class="col-sm-6 mb-3">
                                            <label class="form-label">Mobile No <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="Enter your mobile no." :class="{ 'is-invalid': errors.mobile_no }" v-model="user.mobile_no" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                        <div class="col-sm-6 mb-3">
                                            <label class="form-label">Enter password <span class="text-danger">*</span></label>
                                            <input class="form-control fakepassword" type="password" id="psw-input" placeholder="Enter your password" :class="{ 'is-invalid': errors.password }" v-model="user.password">
                                            <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                                        </div>
                                        <div class="col-sm-6 mb-3">
                                            <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
                                            <input type="password" class="form-control" placeholder="Confirm password" :class="{ 'is-invalid': errors.confirm_password }" v-model="user.confirm_password">
                                            <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                                        </div>
                                        <div>
                                            <button type="submit" class="btn btn-primary w-100 mb-0">Sign up</button>
                                        </div>
                                        <p class="mb-0 mt-3 text-center">©2024 <a target="_blank" href="/">Fastays.</a> All rights reserved</p>
                                    </form>
                                </div>		
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{
    name: "Register",
    data() {
        return {
            user:{
                name: "",
                email: "",
                mobile_no: "",
                password: "",
                confirm_password: "",
                address: "",
                role: "user",
                code: "",
            },
            errors: [],
        }
    },
    methods: {
        registerUser() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("auth", { uri: "vendor/register", data: vm.user })
            .then(function (response) {
                loader.hide();
                // vm.$store.dispatch("setUser", response.data.customer);
                vm.$store.dispatch("setToken", response.data.token);
                vm.$router.push("/");
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
    },
}
</script>