import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import CKEditor from  '@ckeditor/ckeditor5-vue'


createApp(App).use(store).use(router).use(LoadingPlugin).use(VueCarousel).use(CKEditor).mount("#app");
