<template>
    <section class="pt-0">
        <div class="container vstack gap-4">
            <!-- Title START -->
            <div class="row">
                <div class="col-12">
                    <h1 class="fs-4 mb-0"><i class="bi bi-star fa-fw me-1"></i>Images</h1>
                </div>
            </div>
            <!-- Title END -->
    
            <!-- Tabs Content START -->
            <div class="row g-4">
                <div class="col-12">
                    <div class="row g-4">
                        <div class="card border">
                            <div class="card-header border-bottom">
                                <h5 class="card-header-title">Images</h5>
                            </div>
                            <div class="card-body">
                                <div class="row g-3">
                                    <div class="input-group mb-3">
                                        <input type="file" class="form-control" placeholder="Select Files" multiple ref="images" aria-label="Select Files" aria-describedby="basic-addon2">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-primary" @click="UploadImages()">Upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="card-footer">
                                <div style="float: right;">
                                    <div class="col-md-12">
                                        <label class="form-label">Images</label><span class="text-danger"> *</span>
                                        <input type="file" class="form-control" placeholder="Select Files" multiple ref="images">
                                    </div>
                                    <button class="btn btn-sm btn-primary me-2 btn-sm " @click="UploadImages()"><i class="ri-drag-move-fill icon_hgt"></i> Upload</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>


            <div class="card bg-transparent">
                <!-- Card header -->
                <div class="card-header bg-transparent border-bottom px-0">
                    <h4 class="mb-0">Image Gallery</h4>
                </div>
                <!-- Card body -->
                <div class="card-body px-0 pb-0">
                    <div class="row g-4">
                        <div class="col-md-3" v-for="image, key in images" :key="key" >
                            <a class="w-100 h-100" data-glightbox="" data-gallery="gallery" >
                                <div class="card card-element-hover card-overlay-hover overflow-hidden">
                                    <img :src="image?.image" class="card-img" alt="">
                                    <div class="hover-element w-100 h-100">
                                        <i class="bi bi-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                                    </div>
                                </div>
                            </a>
                        </div> 
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>
<script>
import axios from "axios";
export default {
    name: "images",
    data() {
        return {
            errors:[],
            images:{
                image_id:"",
            },
            images:[],
        }
    },
    mounted() {
        let vm=this;
        vm.images.vendor_id = vm.$store.getters.user.vendor_id;
        vm.GetImages();
    },
    methods:{
        GetImages(){
            let vm=this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "hotel/getImage"})
            .then(function (response) {
                loader.hide();
                vm.images = response.data.data
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
        UploadImages(){
            let vm=this;
            let loader = vm.$loading.show();
            let formData = new FormData();
            let imge = vm.$refs.images.files;
            let avatars = []

            if(imge.length){
                for(let i=0; i<imge.length; i++){
                    formData.append('images[]', imge[i]);
                }
                axios.post(vm.$store.state.apiUrl + 'api/v1/'+ 'hotel/addImage', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + this.$store.getters.token,
                    },
                })
                .then((response) => {
                    loader.hide();
                    this.$refs["images"].value = "";
                    vm.$store.dispatch("success", "Data Uploaded Successfully");
                    vm.GetImages();
                })
                .catch(function () {
                    loader.hide();
                    vm.$store.dispatch("error", "Failed to Upload");
                });
            }            
        }
    }

}
</script>