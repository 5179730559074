<template>
    <section class="pt-0">
        <div class="container vstack gap-4">
            <!-- Title START -->
            <div class="row">
                <div class="col-12">
                    <h1 class="fs-4 mb-0"><i class="bi bi-star fa-fw me-1"></i>Facilities</h1>
                </div>
            </div>
            <!-- Title END -->
    
            <!-- Tabs Content START -->
            <div class="row g-4">
                <div class="col-12">
                    <div class="row g-4">
                        <div class="card border">
                            <div class="card-header border-bottom">
                                <h5 class="card-header-title">Facilities</h5>
                            </div>
                            <div class="card-body">
                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <label class="form-label">Facility</label><span class="text-danger"> *</span>
                                        <input type="text" class="form-control" placeholder="Facility" :class="{'is-invalid': errors.facility}" v-model="facility.facility" ref="facility">
                                        <span v-if="errors.facility" class="invalid-feedback">{{ errors.facility[0] }}</span>
                                    </div>
                                    <div class="col-md-3 ">
                                        <label class="form-label">&nbsp;</label>
                                        <div class=" mt-1">
                                        <button v-if="!status" class="btn btn-sm btn-primary me-2 btn-sm " @click="addFacility()"><i class="ri-drag-move-fill icon_hgt"></i> Add</button>
                                        <button v-else class="btn btn-sm btn-primary me-2 btn-sm " @click="updateFacility()"><i class="ri-drag-move-fill icon_hgt"></i> Update</button>
                                    </div>
                                </div>

                                <div class="table-responsive border-0">
                                    <table class="table align-middle p-4 mb-0 table-hover table-shrink">
                                        <!-- Table head -->
                                        <thead class="table-light">
                                            <tr>
                                                <th scope="col" class="border-0 rounded-start">#</th>
                                                <th scope="col" class="border-0" @click="sort(meta.keyword)">Facility Name<i v-if="meta.order_by == 'asc'" class="bi bi-arrow-down text-dark"></i> <i v-else class="bi bi-arrow-up text-dark"></i></th>
                                                <th scope="col" class="border-0 text-center">Actions</th>
                                            </tr>
                                        </thead>
        
                                        <!-- Table body START -->
                                        <tbody class="border-top-0" v-for="facility, key in facilities" :key="key">
                                            <!-- Table item -->
                                            <tr>
                                                <td> <h6 class="mb-0">{{key+1}}</h6> </td>
                                                <td> <h6 class="mb-0">{{facility.facility}}</h6> </td>
                                                <td class="text-center"> <button href="#" class="btn btn-sm btn-light mb-0" style="margin-right: 10px;" @click="getFacility(facility.facility_id)">Edit</button>
                                                    <button href="#" class="btn btn-sm btn-danger mb-0" @click="deleteFacility(facility.facility_id)">Delete</button> </td>
                                            </tr>
                                        </tbody>
                                        <!-- Table body END -->
                                    </table>
                                </div>
                                </div>
                            </div>
                            <div class="card-footer pt-0">
                                <div class="align-items-sm-center">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tabs Content END -->
        </div>	
    </section>
    
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
        name: "Profile",
        components: { Pagination},
        data() {
            return {
                status: false,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "facility_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                facility:{
                    facility_id:"",
                    facility:"",
                    vendor_id:this.$store.getters.user.vendor_id 
                },
                facilities:[],
                hotel:{
                    status:false,
                    hotel_id:"",
                    hotel_name:"",
                    discription:"",
                    address:"",
                    city:"",
                    state:"",
                    country:"",
                    pincode:"",
                    mobile_no:"",
                    email:"",
                    contact_person:"",
                    latitude:"",
                    longitude:"",
                    url:"",
                    vendor_id: this.$store.getters.user.vendor_id         
                },
                errors: [],
            };
        },
        mounted() {
            this.getFacilities();
        },
        methods: {
            getFacilities(){
                let vm=this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/paginateFacilities" , data: vm.meta})
                    .then(function (response) {
                        loader.hide();
                        console.log("response", response.data)
                        vm.facilities = response.data.data
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getFacility(facility_id){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/getFacility" , data:{facility_id:facility_id}})
                    .then(function (response) {
                        loader.hide();
                        vm.facility=response.data.data
                        vm.status=true
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addFacility(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/addFacility" , data: vm.facility})
                    .then(function (response) {
                        loader.hide();
                        console.log("response", response.data)
                        vm.$store.dispatch("success", "Facility is successfully added");
                        vm.facility.facility="";
                        vm.getFacilities();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            updateFacility(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/updateFacility" , data: vm.facility})
                    .then(function (response) {
                        loader.hide();
                        console.log("response", response.data)
                        vm.$store.dispatch("success", "Facility is successfully updated");
                        vm.status=false;
                        vm.facility.facility="";
                        vm.getFacilities();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            deleteFacility(facility_id){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/deleteFacility" , data:{facility_id:facility_id}})
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", "Facility is successfully deleted");
                        vm.getFacilities();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.getFacilities();
            },
            sort(field) {
                // console.log('field:---',field)
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.getFacilities();
            },
           
        },
    };
</script>