import { createStore } from "vuex";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";

export default createStore({
	state: {
		// apiUrl: "http://192.168.0.213/fastays-laravel-app/public/",
		apiUrl: "http://api.fastays.com/",
		user: null,
		authenticated: false,
		token: '',
	},
	getters: {
		user(state) {
			return state.user;
		},
		token(state) {
			return state.token;
		},
		apiUrl(state) {
			return state.apiUrl;
		},
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		setToken(state, token) {
			state.token = token;
		},
		setUrl(state, url) {
			state.apiUrl = url;
		},
	},
	actions: {
		async setUser(context, payload) {
			await context.commit("setUser", payload);
		},

		async setToken(context, payload) {
			await context.commit("setToken", payload);
		},

		async logout(context) {
			await context.commit("setUser", null);
		},

		auth(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(this.state.apiUrl + 'api/v1/' + payload.uri, payload.data)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		get(context, payload) {
			return new Promise((resolve, reject) => {
				axios.get(this.state.apiUrl + 'api/v1/' + payload.uri, payload.data, {
					headers: {
						Authorization: "Bearer" + " " + context.getters.token,
					},
				})
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		post(context, payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl + 'api/v1/' + payload.uri, payload.data, {
					headers: {
						Authorization: "Bearer" + " " + context.getters.token,
					},
				})
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
					if (error.response.data.message == "Unauthenticated.") {
						context.commit("setUser", null);
						window.location.href = "/#/";
						window.location.reload();
					}
				});
			});
		},

		async error(context, description) {
			await createToast(
				{
					title: "Error",
					description: description || "The given data was invalid.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "danger",
					position: "top-right",
				}
			);
		},

		async success(context, description) {
			await createToast(
				{
					title: "Success",
					description: description || "Data is successfuly subbmited.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "success",
					position: "top-right",
				}
			);
		},

		async info(context, description) {
			await createToast(
				{
					title: "Info",
					description: description || "Not enoguh input data",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "info",
					position: "top-right",
				}
			);
		},
	},
	modules: {
	}
})

