<template>
    <section class="pt-0">
        <div class="container vstack gap-4">
            <!-- Title START -->
            <div class="row">
                <div class="col-12">
                    <h1 class="fs-4 mb-0"><i class="bi bi-grid fa-fw me-1"></i>Hotel Detail</h1>
                </div>
            </div>
            <!-- Title END -->
    
            <!-- Tabs Content START -->
            <div class="row g-4">
                <div class="col-12">
                    <div class="tab-content">
                        <!-- Tab content 1 START -->
                        <div class="tab-pane show active" id="tab-1">
                            <div class="row g-4">
                                <div class="card border">
                                    <div class="card-header border-bottom">
                                        <h5 v-if="hotel.status" class="card-header-title">Edit Hotel</h5>
                                        <h5 v-else class="card-header-title">Add Hotel</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row g-3">
                                            <div class="col-md-8">
                                                <label class="form-label">Hotel Name</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Hotel Name" :class="{'is-invalid': errors.hotel_name}" v-model="hotel.hotel_name" ref="hotel_name">
                                                <span v-if="errors.hotel_name" class="invalid-feedback">{{ errors.hotel_name[0] }}</span>
                                            </div>
                                            
                                            <div class="col-md-4">
                                                <label class="form-label">City</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="City" :class="{'is-invalid': errors.city}" v-model="hotel.city" ref="city">
                                                <span v-if="errors.city" class="invalid-feedback">{{ errors.city[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">State</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="State" :class="{'is-invalid': errors.state}" v-model="hotel.state">
                                                <span v-if="errors.state" class="invalid-feedback">{{ errors.state[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Country</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Country" :class="{'is-invalid': errors.country}" v-model="hotel.country">
                                                <span v-if="errors.country" class="invalid-feedback">{{ errors.country[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Pin Code</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Pin Code" :class="{'is-invalid': errors.pincode}" v-model="hotel.pincode">
                                                <span v-if="errors.pincode" class="invalid-feedback">{{ errors.pincode[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Mobile No.</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Mobile No." :class="{'is-invalid': errors.mobile_no}" v-model="hotel.mobile_no">
                                                <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Email</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Email" :class="{'is-invalid': errors.email}" v-model="hotel.email">
                                                <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Contact Person</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Contact Person" :class="{'is-invalid': errors.contact_person}" v-model="hotel.contact_person">
                                                <span v-if="errors.contact_person" class="invalid-feedback">{{ errors.contact_person[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Latitude</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Latitude" :class="{'is-invalid': errors.latitude}" v-model="hotel.latitude">
                                                <span v-if="errors.latitude" class="invalid-feedback">{{ errors.latitude[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Longitude</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Longitude" :class="{'is-invalid': errors.longitude}" v-model="hotel.longitude">
                                                <span v-if="errors.longitude" class="invalid-feedback">{{ errors.longitude[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">URL</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="URL" :class="{'is-invalid': errors.url}" v-model="hotel.url">
                                                <span v-if="errors.url" class="invalid-feedback">{{ errors.url[0] }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label">Description</label><span class="text-danger"> *</span>
                                                <textarea type="text" rows="2" class="form-control" placeholder="Description" :class="{'is-invalid': errors.description}" v-model="hotel.description"></textarea>
                                                <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label">Address</label><span class="text-danger"> *</span>
                                                <textarea type="text" class="form-control" placeholder="Hotel Address" :class="{'is-invalid': errors.address}" v-model="hotel.address"></textarea>
                                                <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
                                            </div>
                                        </div>
                                        <!-- Save button -->
                                        <div class="d-flex justify-content-end mt-4">
                                            <!-- <a href="javascript:void(0)" class="btn text-secondary border-0 me-2">Discard</a> -->
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary" @click="updateProfile()">Save change</a> -->
                                            <button type="reset" class="btn btn-danger me-2" @click.prevent="discard()"><i
                                                class="ri-close-line icon_hgt"></i> DISCARD</button>
                                            <button v-if="hotel.status" type="submit" class="btn btn-primary" @click="udateHotel()"><i class="ri-save-line icon_hgt"></i>
                                                UPDATE</button>
                                            <button v-else type="submit" class="btn btn-primary" @click="addHotel()"><i class="ri-save-line icon_hgt"></i>
                                                SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Tab content 1 END -->
    
                       
                    </div>
                </div>
            </div>
            <!-- Tabs Content END -->
        </div>	
    </section>
</template>
<script>
    export default {
        name: "Profile",
        data() {
            return {
                status: true,
                hotel:{
                    status:false,
                    hotel_id:"",
                    hotel_name:"",
                    description:"",
                    address:"",
                    city:"",
                    state:"",
                    country:"",
                    pincode:"",
                    mobile_no:"",
                    email:"",
                    contact_person:"",
                    latitude:"",
                    longitude:"",
                    url:"",
                    vendor_id: this.$store.getters.user.vendor_id          
                },
                roles: [],
                users: [],
                errors: [],
            };
        },
        mounted() {
            this.getHotel();
        },
        methods: {
            getHotel(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/getHotel"})
                    .then(function (response) {
                        loader.hide();
                        console.log("hotel", response.data.data)
                        if(response.data.length ==0){
                            vm.hotel.status = false;                            
                        }else{
                            console.log("hotel1")
                            vm.hotel=response.data.data
                            vm.hotel.status = true
                        }
                        // vm.hotel=response.data.data                        
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error.response?.data)
                        vm.errors = error.response?.data?.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addHotel(){
                let vm = this;
                // let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/addHotel" , data: vm.hotel})
                    .then(function (response) {
                        // loader.hide();
                        console.log("response", response.data)
                        vm.hotel.status = true;
                        vm.$store.dispatch("success", "Hotel is successfully added");

                    })
                    .catch(function (error) {
                        // loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            udateHotel(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotel/updateHotel", data: vm.hotel})
                    .then(function (response) {
                        loader.hide();
                        console.log("response", response.data)
                        vm.$store.dispatch("success", "Hotel is successfully updated");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
			let vm = this;
			vm.hotel.hotel_name="";
            vm.hotel.description="";
            vm.hotel.address="";
            vm.hotel.city="";
            vm.hotel.state="";
            vm.hotel.country="";
            vm.hotel.pincode="";
            vm.hotel.mobile_no="";
            vm.hotel.email="";
            vm.hotel.contact_person="";
            vm.hotel.latitude="";
            vm.hotel.longitude="";
            vm.hotel.url="";
		}
           
        },
    };
</script>