<template>
    <section class="pt-0">
        <div class="container vstack gap-4">
            <!-- Title START -->
            <div class="row">
                <div class="col-12">
                    <h1 class="fs-4 mb-0"><i class="bi bi-star fa-fw me-1"></i>Policies</h1>
                </div>
            </div>
            <!-- Title END -->
    
            <!-- Tabs Content START -->
            <div class="row g-4">
                <div class="col-12">
                    <div class="row g-4">
                        <div class="card border">
                            <div class="card-header border-bottom">
                                <h5 class="card-header-title">Policies</h5>
                            </div>
                            <div class="card-body">
                                <div class="row g-3">
                                    <div class="col-md-12">
                                        <label class="form-label">Policy</label><span class="text-danger"> *</span>
                                        <Ckeditor :editor="editor" placeholder="Policy" :class="{'is-invalid': errors.policy}" v-model="policies.policy"></Ckeditor>
                                        <!-- <input type="text" class="form-control" placeholder="Policy" :class="{'is-invalid': errors.policy}" v-model="policies.policy"> -->
                                        <!-- rows="4" cols="50" -->
                                        <!-- <textarea  class="form-control" placeholder="Policy" :class="{'is-invalid': errors.policy}" v-model="policies.policy"></textarea> -->
                                        <span v-if="errors.policy" class="invalid-feedback">{{ errors.policy[0] }}</span>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Check In Instructions</label><span class="text-danger"> *</span>
                                        <Ckeditor :editor="editor" v-model="policies.check_in_instructions" :class="{'is-invalid': errors.check_in_instruction}"></Ckeditor>
                                        <!-- <input type="textarea" class="form-control" placeholder="Check In Instructions" :class="{'is-invalid': errors.check_in_instruction}" v-model="policies.check_in_instruction"> -->
                                        <!-- <textarea class="form-control" placeholder="Check In Instructions" :class="{'is-invalid': errors.check_in_instruction}" v-model="policies.check_in_instructions"></textarea> -->
                                        <span v-if="errors.check_in_instruction" class="invalid-feedback">{{ errors.check_in_instruction[0] }}</span>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Fees</label><span class="text-danger"> *</span>
                                        <Ckeditor :editor="editor" placeholder="Fees" :class="{'is-invalid': errors.fees}"  v-model="policies.fees"></Ckeditor>
                                        <!-- <input type="textarea" class="form-control" placeholder="Cancelation Policies" :class="{'is-invalid': errors.cancelation_policies}" v-model="policies.cancelation_policies"> -->
                                        <!-- <textarea class="form-control" placeholder="Cancelation Policies" :class="{'is-invalid': errors.cancelation_policies}"  v-model="policies.cancellation_policy"></textarea> -->
                                        <span v-if="errors.fees" class="invalid-feedback">{{ errors.fees[0] }}</span>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Cancelation Policies</label><span class="text-danger"> *</span>
                                        <Ckeditor :editor="editor" placeholder="Cancelation Policies" :class="{'is-invalid': errors.cancelation_policies}"  v-model="policies.cancellation_policy"></Ckeditor>
                                        <!-- <input type="textarea" class="form-control" placeholder="Cancelation Policies" :class="{'is-invalid': errors.cancelation_policies}" v-model="policies.cancelation_policies"> -->
                                        <!-- <textarea class="form-control" placeholder="Cancelation Policies" :class="{'is-invalid': errors.cancelation_policies}"  v-model="policies.cancellation_policy"></textarea> -->
                                        <span v-if="errors.cancelation_policies" class="invalid-feedback">{{ errors.cancelation_policies[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div style="float: right;">
                                    <button v-if="!status" class="btn btn-sm btn-primary me-2 btn-sm " @click="AddPolicies()"><i class="ri-drag-move-fill icon_hgt"></i> Add</button>
                                    <button v-else class="btn btn-sm btn-primary me-2 btn-sm " @click="UpdatePolicies()"><i class="ri-drag-move-fill icon_hgt"></i> Update</button>
                                </div>
                            </div>

                            <!-- <div class="col-md-12">
                                <label class="form-label">Cancelation Policies</label><span class="text-danger"> *</span>
                                <input type="file" class="form-control" placeholder="Select Files" multiple ref="images">
                                <button class="btn btn-sm btn-primary me-2 btn-sm " @click="UploadImages()"><i class="ri-drag-move-fill icon_hgt"></i> Upload</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
export default {
    name: "policies",
    components:{ClassicEditor},
    data() {
        return {
            editor: ClassicEditor,
            policies: {
                vendor_id: "",
                policy: "",
                check_in_instructions: "",
                fees: '',
                cancellation_policy: "",
            },            
            status:false,
            policy:[],
            errors:[]
        }
    },
    mounted() {
        let vm=this;
        vm.policies.vendor_id = vm.$store.getters.user.vendor_id;
        vm.GetPolicies();
    },
    methods:{
        GetPolicies(){
            let vm=this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "hotel/getPolicy"})
            .then(function (response) {
                loader.hide();
                if(response.data.length == 0){
                    vm.status = false;                            
                }else{
                    vm.policies = response?.data?.data
                    vm.policies.fees = response?.data?.data?.fees.toString()
                    vm.status = true;
                }
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
        AddPolicies(){
            let vm=this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "hotel/addPolicy" , data: vm.policies})
            .then(function (response) {
                loader.hide();
                vm.policy = response.data.data
                vm.status = true;
                vm.$store.dispatch("success", "Policy is successfully added");
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
        UpdatePolicies(){
            let vm=this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "hotel/updatePolicy" , data: vm.policies})
            .then(function (response) {
                loader.hide();
                vm.policy = response.data.data
                vm.status = true;
                vm.$store.dispatch("success", "Policy is successfully updated");
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },

        // UploadImages(){
        //     let vm=this;
        //     let loader = vm.$loading.show();
        //     let formData = []
        //     let imge = vm.$refs.images.files;
        //     let avatars = []

        //     if(imge.length){
        //         for(let i=0; i<imge.length; i++){
        //             formData[i] = new FormData();
        //             formData[i].append('avatars', imge[i]);
        //         }
        //         vm.$store.dispatch("post", { uri: "hotel/addImage" , data: formData})
        //         .then(function (response) {
        //             loader.hide();
        //             console.log('response_images:----',response)
        //         })
        //         .catch(function (error) {
        //             loader.hide();
        //             console.log('response_error:----',error)
        //         });
        //     }

            
        // }
    }

}
</script>