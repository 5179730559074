import { createRouter, createWebHashHistory } from "vue-router";

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import Profile from "@/views/auth/Profile.vue"

import Dashboard from "@/views/Dashboard.vue";
import AddListing from "@/views/AddListing.vue";
import Bookings from "@/views/Bookings.vue";
import AddHotel from "@/views/hotel/AddHotel.vue";
import Facilities from "@/views/Facilities.vue";
import Policies from "@/views/Policies.vue";
import Images from "@/views/Images.vue";


import Home from "@/views/Home.vue";

//Rooms
import Rooms from "@/views/rooms/Index.vue";
import AddRoom from "@/views/rooms/Create.vue";

const routes = [
	{
		path: "/",
		name: "login",
		component: Login,
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
	},
	{
		path: "/profile-settings",
		name: "Profile",
		component: Profile,
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		component: Dashboard,
	},
	{
		path: "/add-listing",
		name: "AddListing",
		component: AddListing,
	},
	{
		path: "/bookings",
		name: "Bookings",
		component: Bookings,
	},
	{
		path: "/rooms",
		name: "Rooms",
		component: Rooms,
	},
	{
		path: "/hotel-detail",
		name: "AddHotel",
		component: AddHotel,
	},
	{
		path: "/facilities",
		name: "Facilities",
		component: Facilities,
	},
	{
		path: "/policies",
		name: "Policies",
		component: Policies,
	},
	{
		path: "/images",
		name: "Images",
		component: Images,
	},






	{
		path: "/home",
		name: "home",
		component: Home,
	},
	{
		path: "/about-us",
		name: "About",
		component: About,
	},
	{
		path: "/contact-us",
		name: "Contact",
		component: Contact,
	},

	//Rooms
	{
		path: "/rooms/create",
		name: "AddRoom",
		component: AddRoom,
	},
	{
		path: "/rooms/update/:room_id",
		name: "UpdateRoom",
		component: AddRoom,
	},
	{
		path: "/rooms/index",
		name: "Rooms",
		component: Rooms,
	},


];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
