<template>
    <section class="pt-0">
        <div class="container vstack gap-4">
            <!-- Title START -->
            <div class="row">
                <div class="col-12">
                    <h1 class="fs-4 mb-0"><i class="bi bi-grid fa-fw me-1"></i>Add Room</h1>
                </div>
            </div>
            <!-- Title END -->
    
            <!-- Tabs Content START -->
            <div class="row g-4">
                <div class="col-12">
                    <div class="tab-content">
                        <!-- Tab content 1 START -->
                        <div class="tab-pane show active" id="tab-1">
                            <div class="row g-4">
                                <div class="card border">
                                    <div class="card-header border-bottom">
                                        <h5 v-if="room_update" class="card-header-title">Update Room</h5>
                                        <h5 v-else class="card-header-title">Add New Room</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row g-3">
                                            <div class="col-md-4">
                                                <label class="form-label">Room name</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Room name" :class="{'is-invalid': errors.room_name}" v-model="room.room_name" ref="room_name">
                                                <span v-if="errors.room_name" class="invalid-feedback">{{ errors.room_name[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Room Price *</label>
                                                <input type="number" class="form-control" placeholder="Enter price"
                                                    :class="{ 'is-invalid': errors.price }" v-model="room.price">
                                                <span v-if="errors.price" class="invalid-feedback">{{ errors.price[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label"> Discount</label><span class="text-danger"> *</span>
                                                <input type="number" class="form-control" placeholder="Enter discount"
                                                    :class="{ 'is-invalid': errors.discount }" v-model="room.discount">
                                                <span v-if="errors.discount" class="invalid-feedback">{{ errors.discount[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">GST</label><span class="text-danger"> *</span>
                                                <input type="number" class="form-control" placeholder="Enter GST"
                                                    :class="{ 'is-invalid': errors.GST }" v-model="room.GST">
                                                <span v-if="errors.GST" class="invalid-feedback">{{ errors.GST[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">TDS</label><span class="text-danger"> *</span>
                                                <input type="number" class="form-control" placeholder="Enter TDS"
                                                    :class="{ 'is-invalid': errors.TDS }" v-model="room.TDS">
                                                <span v-if="errors.TDS" class="invalid-feedback">{{ errors.TDS[0] }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Room image</label><span class="text-danger"> *</span>
                                                <input class="form-control" type="file" name="my-image" id="image-2"
                                                    :class="{ 'is-invalid': errors.image }" accept="image/gif, image/jpeg, image/png"
                                                    @change="onImageChange($event)">
                                                <span v-if="errors.image" class="invalid-feedback">{{ errors.image[0] }}</span>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="form-label">Short Description</label>
                                                <textarea name="" class="form-control" id="" cols="30" rows="3"
                                                    v-model="room.description"></textarea>
                                            </div>
                                            
                                        </div>
                                        <!-- Save button -->
                                        <div class="d-flex justify-content-end mt-4">
                                            <button type="submit" class="btn btn-primary" @click="updateRoom()" v-if="room_update"><i class="ri-save-line icon_hgt"></i>
                                                UPDATE</button>
                                            <button v-else type="submit" class="btn btn-primary" @click="addRoom()"><i class="ri-save-line icon_hgt"></i>
                                                SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Tab content 1 END -->
    
                       
                    </div>
                </div>
            </div>
            <!-- Tabs Content END -->
        </div>	
    </section>
</template>
<script>
export default {
    data() {
        return {
            room: {
                room_id: '',
                image: '',
                room_name: '',
                description: '',
                price: '',
                discount: '',
                GST: '',
                TDS: ''
            },
            room_update : false,
            errors: []
        }
    },
    
    beforeRouteEnter(to, from, next){
        next(vm => {
            if(to.name == 'UpdateRoom'){
                vm.room_update = true;
                vm.getRoom(to.params.room_id);
            }
        })
    },

    methods: {
        addRoom() {
            let vm = this;
            let formData = new FormData();
            formData.append('image', this.room.image);
            formData.append('room_name', this.room.room_name);
            formData.append('description', this.room.description);
            formData.append('price', this.room.price);
            formData.append('discount', this.room.discount);
            formData.append('GST', this.room.GST);
            formData.append('TDS', this.room.TDS);
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: "hotel/addRoom", data: formData })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.$router.push('index');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateRoom() {
            let vm = this;
            let formData = new FormData();
            formData.append('room_id', this.room.room_id);
            formData.append('image', this.room.image);
            formData.append('room_name', this.room.room_name);
            formData.append('description', this.room.description);
            formData.append('price', this.room.price);
            formData.append('discount', this.room.discount);
            formData.append('GST', this.room.GST);
            formData.append('TDS', this.room.TDS);
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: "hotel/updateRoom", data: formData })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.$router.push('/rooms/index');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getRoom(room_id){
            let vm = this;
            this.$store.dispatch('post', {uri:'hotel/getRoom', data: {room_id: room_id}})
            .then(response => {
                vm.room = response.data.data;
            })
            .catch(error => {
                vm.$store.dispatch('error',error.response.data.message);
            })
        },

        onImageChange(e) {
            this.room.image = e.target.files[0];
        }
    }
}
</script>