<template>
	<div v-if="$store.getters?.user">
		<Header></Header>
		<main>
			<Main></Main>
			<router-view />
		</main>
		<Footer></Footer>
	</div>
	<div v-else>
		<router-view />
	</div>
	
	<div class="back-top"></div>
</template>
<script>
	import Header from "@/components/Header.vue"
	import Footer from "@/components/Footer.vue";
	import Main from "@/components/Main.vue";
	export default {
		name: 'App',
		components: {Header, Footer, Main },
		created() {
			if (sessionStorage.getItem("user") ) {
                this.$store.dispatch('setUser', JSON.parse(sessionStorage.getItem("user")))
                sessionStorage.removeItem('user')
            }
            if (sessionStorage.getItem("token") ) {
                this.$store.dispatch('setToken', sessionStorage.getItem("token"))
                sessionStorage.removeItem('token')
            }
			window.addEventListener("beforeunload", () => {
				sessionStorage.setItem("user", JSON.stringify(this.$store?.getters?.user));
				sessionStorage.setItem("token", this.$store?.getters?.token);
			})
		}
	};
</script>