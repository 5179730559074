<template>
    <section class="pt-0">
        <div class="container vstack gap-4">
            <!-- Title START -->
            <div class="row">
                <div class="col-12">
                    <h1 class="fs-4 mb-0"><i class="bi bi-gear fa-fw me-1"></i>Settings</h1>
                </div>
            </div>
            <!-- Title END -->
    
            <!-- Tabs START -->
            <div class="row g-4">
                <div class="col-12">
                    <div class="bg-light pb-0 px-2 px-lg-0 rounded-top">
                        <ul class="nav nav-tabs nav-bottom-line nav-responsive border-0 nav-justified" role="tablist">
                            <li class="nav-item"> <a class="nav-link mb-0 active" data-bs-toggle="tab" href="#tab-1"><i class="fas fa-cog fa-fw me-2"></i>Edit Profile</a> </li>
                            <!-- <li class="nav-item"> <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-2"><i class="fas fa-bell fa-fw me-2"></i>Notification Settings</a> </li>
                            <li class="nav-item"> <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-3"><i class="fas fa-user-circle fa-fw me-2"></i>Account Settings</a> </li> -->
                        </ul>
                    </div>
                </div>
            </div>	
            <!-- Tabs END -->
    
            <!-- Tabs Content START -->
            <div class="row g-4">
                <div class="col-12">
                    <div class="tab-content">
                        <!-- Tab content 1 START -->
                        <div class="tab-pane show active" id="tab-1">
                            <div class="row g-4">
                                <!-- Edit profile START -->
                                <div class="col-md-7">
                                    <div class="card border">
                                        <div class="card-header border-bottom">
                                            <h5 class="card-header-title">Edit Profile</h5>
                                        </div>
                                        <div class="card-body">
                                            <!-- Full name -->
                                            <!-- Profile picture -->
                                            <div class="mb-3">
                                                <label class="form-label">Profile picture</label>
                                                <!-- Avatar upload START -->
                                                <div class="d-flex align-items-center">
                                                    <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                        <!-- Avatar place holder -->
                                                        <span class="avatar avatar-xl">
                                                            <img id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow" :src="vendor.avatar" alt="">
                                                        </span>
                                                    </label>
                                                    <!-- Upload button -->
                                                    <label class="btn btn-sm btn-primary-soft mb-0" for="uploadfile-1">Change</label>
                                                    <input id="uploadfile-1" class="form-control d-none" type="file" @change="onImageChange($event)">
                                                </div>
                                                <!-- Avatar upload END -->
                                            </div>
                                            
                                            <div class="mb-3">
                                                <label class="form-label">Name</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Name" :class="{'is-invalid': errors.name}" v-model="vendor.name" ref="name">
                                                <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                            </div>
                                            <!-- Email id -->
                                            <div class="mb-3">
                                                <label class="form-label">Email id</label><span class="text-danger"> *</span>
                                                <input type="email" class="form-control" placeholder="Enter your email id" :class="{'is-invalid': errors.email}" v-model="vendor.email">
                                                <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                            </div>
                                            <!-- Mobile number -->
                                            <div class="mb-3">
                                                <label class="form-label">Mobile number</label><span class="text-danger"> *</span>
                                                <input type="text" class="form-control" placeholder="Enter your mobile number" :class="{'is-invalid': errors.mobile_no}" v-model="vendor.mobile_no">
                                                <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                            </div>
                                            <!-- Save button -->
                                            <div class="d-flex justify-content-end mt-4">
                                                <!-- <a href="javascript:void(0)" class="btn text-secondary border-0 me-2">Discard</a> -->
                                                <a href="javascript:void(0)" class="btn btn-primary" @click="updateProfile()">Save change</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Edit profile END -->
    
                                <!-- Update Password START -->
                                <div class="col-md-5">
                                    <div class="card border">
                                        <div class="card-header border-bottom">
                                            <h5 class="card-header-title">Update Password</h5>
                                        </div>
                                        <!-- Card body START -->
                                        <form class="card-body">
                                            <!-- Current password -->
                                            <div class="mb-3">
                                                <label class="form-label">Current password</label>
                                                <input class="form-control" type="password" placeholder="Enter current password" :class="{'is-invalid': errors.current_password}" v-model="vendor.current_password" @keyup="test(vendor.current_password,'current_password')">
                                                <span v-if="errors.current_password" class="invalid-feedback">{{ errors.current_password[0] }}</span>
                                            </div>
                                            <!-- New password -->
                                            <div class="mb-3">
                                                <label class="form-label"> Enter new password</label>
                                                <div class="input-group">
                                                    <input class="form-control fakepassword" type="password" id="psw-input" placeholder="Enter new password" :class="{'is-invalid': errors.new_password}" v-model="vendor.new_password" @keyup="test(vendor.new_password,'new_password')">
                                                    <span class="input-group-text p-0 bg-transparent">
                                                        <i class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2"></i>
                                                    </span>
                                                    <span v-if="errors.new_password" class="invalid-feedback">{{ errors.new_password[0] }}</span>
                                                </div>
                                                
                                            </div>
                                            <!-- Confirm -->
                                            <div>
                                                <label class="form-label">Confirm new password</label>
                                                <input class="form-control" type="password" placeholder="Confirm new password" :class="{'is-invalid': errors.confirm_password}" v-model="vendor.confirm_password" @keyup="test(vendor.confirm_password,'confirm_password')">
                                                <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                                            </div>
    
                                            <div class="text-end mt-3">
                                                <a href="javascript:void(0)" class="btn btn-primary mb-0" @click="updatePassword()">Change Password</a>
                                            </div>
                                        </form>
                                        <!-- Card body END -->
                                    </div>
                                </div>
                                <!-- Update Password END -->
                            </div>
                        </div>
                        <!-- Tab content 1 END -->
    
                       
                    </div>
                </div>
            </div>
            <!-- Tabs Content END -->
        </div>	
    </section>
</template>
<script>
export default {
        name: "Profile",
        data() {
            return {
                status: true,
                vendor: {
                    vendor_id: "",
                    name: "",
                    email: "",
                    mobile_no: "",
                    address: "",
                    avatar:"",
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                },
                roles: [],
                users: [],
                errors: [],
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.vendor.vendor_id = vm.$store.getters.user?.vendor_id;
                let loader = vm.$loading.show();
                let uri = { uri: "vendor/me", data: vm.vendor };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.vendor = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            });
        },
        methods: {
            test( password,field_name){
                console.log("key", password,field_name)
                let vm = this;
                if(field_name=="current_password"){
                    vm.errors.current_password="";
                }

                if(field_name=="new_password"){
                    vm.errors.new_password="";
                }
               
                if(field_name=="confirm_password"){
                    vm.errors.confirm_password="";
                }
                
            },
            updateProfile() {
                let vm = this;
                let loader = vm.$loading.show();
                let data = new FormData();
                data.append("name", this.vendor.name);
                data.append("email", this.vendor.email);
                data.append("mobile_no", this.vendor.mobile_no);
                data.append("avatar", this.vendor.avatar);

                vm.$store
                    .dispatch("post", { uri: "vendor/updateProfile", data: data })
                    .then(function (response) {
                        console.log(response.data);
                        loader.hide();
                        console.log("response",response)
                        vm.$store.dispatch("success", "Profile is successfully updated");
                        vm.$store.dispatch("setUser", response.data.data);
                        vm.errors = [];
                        // vm.$router.push('/profile-settings');
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updatePassword() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "vendor/changePassword", data: vm.vendor })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Password is successfully updated");
                        // vm.$router.push('/');
                        vm.vendor.current_password="";
                        vm.vendor.new_password="";
                        vm.vendor.confirm_password="";
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response?.data?.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                vm.image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(vm.image);
                reader.onload = (e) => {
                    vm.vendor.avatar = e.target.result;
                };
            },
           
        },
    };
</script>