<template>
    <section class="vh-xxl-100">
        <div class="container d-flex px-0 px-sm-4">
            <div class="row justify-content-center align-items-center m-auto">
                <div class="col-12">
                    <div class="bg-mode shadow rounded-3 overflow-hidden">
                        <div class="row g-0">
                            <div class="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                <div class="p-3 p-lg-5">
                                    <img src="assets/images/element/signin.svg" alt="">
                                </div>
                                <div class="vr opacity-1 d-none d-lg-block"></div>
                            </div>
                            <div class="col-lg-6 order-1">
                                <div class="p-4 p-sm-7">
                                    <img class="h-120px mb-4" src="assets/images/logo-icon.png" alt="logo">
                                    <h1 class="mb-2 h3">Vendor Login</h1>
                                    <p class="mb-0">New here?<router-link to="/register"> Create an Account</router-link></p>
                                    <form class="mt-4 text-start" @submit.prevent="login">
                                        <div class="mb-3">
                                            <label class="form-label">Enter email id</label>
                                            <input type="email" class="form-control" placeholder="Enter your email" :class="{ 'is-invalid': errors.email }" v-model="user.email">
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>
                                        <div class="mb-3 position-relative">
                                            <label class="form-label">Enter password</label>
                                            <input class="form-control fakepassword" type="password" id="psw-input" placeholder="Enter your password" :class="{ 'is-invalid': errors.password }" v-model="user.password">
                                            <span class="position-absolute top-50 end-0 translate-middle-y p-0 mt-3">
                                                <i class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2"></i>
                                            </span>
                                            <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                                        </div>
                                        <div class="mb-3 d-sm-flex justify-content-between">
                                            <div>
                                                <input type="checkbox" class="form-check-input me-2" id="rememberCheck">
                                                <label class="form-check-label" for="rememberCheck">Remember me?</label>
                                            </div>
                                            <a href="forgot-password.html">Forgot password?</a>
                                        </div>
                                        <div>
                                            <button type="submit" class="btn btn-primary w-100 mb-0">Login</button>
                                        </div>
                                        <p class="mb-0 mt-3 text-center">©2024 <a target="_blank" href="javascript:void(0)">Fastays.</a> All rights reserved</p>
                                    </form>
                                </div>		
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{
    name: "Login",
    data() {
        return {
            user: {
                email: "",
                password: ""
            },
            errors: [],
        };
    },
    methods:{
      login() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("auth", { uri: "vendor/login", data: vm.user })
            .then(function (response) {
                loader.hide();
                vm.$store.dispatch("setUser", response.data.vendor);
                vm.$store.dispatch("setToken", response.data.token);
                console.log('response.data.token:---',response.data.token)
                vm.$router.push("/dashboard");
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
    }
}
</script>