<template>
    <header class="navbar-light header-sticky">
        <nav class="navbar navbar-expand-xl">
            <div class="container">
                <router-link to="/" class="navbar-brand">
                    <img class="light-mode-item navbar-brand-item" src="assets/images/logo.png" alt="logo" />
                    <img class="dark-mode-item navbar-brand-item" src="assets/images/logo-light.png" alt="logo" />
                </router-link>
                <button class="navbar-toggler ms-auto ms-sm-0 p-0 p-sm-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-animation">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span class="d-none d-sm-inline-block small">Menu</span>
                </button>
                <button
                    class="navbar-toggler ms-sm-auto mx-3 me-md-0 p-0 p-sm-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCategoryCollapse"
                    aria-controls="navbarCategoryCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i class="bi bi-grid-3x3-gap-fill fa-fw"></i><span class="d-none d-sm-inline-block small">Category</span>
                </button>
                <div class="navbar-collapse collapse" id="navbarCollapse">
                    <ul class="navbar-nav navbar-nav-scroll me-auto">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/about-us">About Us</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/contact-us">Contact Us</router-link>
                        </li>
                    </ul>
                </div>
                <ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">
                    <li class="nav-item ms-3 dropdown" v-if="$store.getters.user">
                        <a class="avatar avatar-sm p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                            <img class="avatar-img rounded-2" src="assets/images/avatar/profile.png" alt="avatar" />
                        </a>

                        <ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown">
                            <li class="px-3 mb-3">
                                <div class="d-flex align-items-center">
                                    <div class="avatar me-3">
                                        <img class="avatar-img rounded-circle shadow" src="assets/images/avatar/profile.png" alt="avatar" />
                                    </div>
                                    <div>
                                        <a class="h6 mt-2 mt-sm-0" href="#">{{ $store.getters.user.name }}</a>
                                        <p class="small m-0">{{ $store.getters.user.email }}</p>
                                    </div>
                                </div>
                            </li>
                            <li><hr class="dropdown-divider" /></li>
                            <li>
                                <router-link class="dropdown-item" to="profile-settings"><i class="bi bi-gear fa-fw me-2"></i>Profile Settings</router-link>
                            </li>
                            <li><hr class="dropdown-divider" /></li>
                            <li>
                                <a class="dropdown-item bg-danger-soft-hover" href="#" @click.prevent="logout()">
                                    <i class="bi bi-power fa-fw me-2"></i>Sign Out
                                </a>
                            </li>
                        </ul>
                    </li>
					<li class="nav-item ms-2 d-none d-sm-block" v-else>
						<router-link to="/" class="btn btn-sm btn-primary mb-0">
							<i class="fa-solid fa-right-to-bracket me-2"></i>Sign in
						</router-link>
					</li>
                </ul>
            </div>
        </nav>
    </header>
    
</template>
<script>
    export default {
        methods: {
            logout() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "vendor/logout", data: vm.$store.getters.user })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("vendor/logout");
                    vm.$store.dispatch("setUser", null);
                    vm.$store.commit("setToken", "");
                    
                    vm.$router.push("/");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        },
    };
</script>

<style scoped>
@media (min-width: 1200px){


header.header-sticky-on {
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0px;
    right: 0px;
    background-color: var(--bs-body-bg);
    -webkit-animation: fadeInDown 0.5s;
    animation: fadeInDown 0.5s;
    width: 100%;
    border-bottom: 1px solid var(--bs-gray-200);
}
}
</style>
